import React, { useEffect, useState } from 'react';



const HeaderCountdownTimer = ({ targetDate }) => {
  const [countdown, setCountdown] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });



  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate.getTime() - now;



      if (distance < 0) {
        // Countdown reached the target date, clear the interval
        clearInterval(interval);
        window.location.reload();

      } else {
        // Calculate remaining days, hours, and minutes
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);



        // Update the countdown state
        setCountdown({ days, hours, minutes, seconds });
      }
    }, 1000);



    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [targetDate]);



  return (
    <div className="countdown-container">
      <span className="countdown-label">Survey Ends In</span>
      <div className="countdown-timer">
        <div className="">
        {countdown.days} :<span className="countdown-label">{countdown.days > 1 ? 'Days' : 'Day'}</span>
        </div>
        <div className="">
        {countdown.hours} :<span className="countdown-label">{countdown.hours > 1 ? 'Hrs' : 'Hr'}</span>
        </div>
        <div className="">
        {countdown.minutes} :<span className="countdown-label">{countdown.minutes > 1 ? 'Mins' : 'Min'} </span>
        </div>
        <div className="">
        {countdown.seconds}<span className="countdown-label">Sec</span>
        </div>
      </div>
    </div>
  );
};



export default HeaderCountdownTimer;