import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import SurveyLanguageDropdown from '../dropdowns/SurveyLanguageDropdown';
import CountdownTimer from './CountdownTimer';
import { getAllQuestionAndAnswer, getDataByLanguage, startSurvey, submitSurvey } from "./service";
import SingleAnswerType from '../answerTypes/singleAnswerType';
import MultipleAnswerType from '../answerTypes/multipleAnswerType';
import NumberScaleAnswerType from '../answerTypes/numberscaleAnswerType';
import SubjectiveAnserType from '../answerTypes/subjectiveAnserType';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NumberScaleComponent from '../answerTypes/numberscaleAnswerType';
import SurveyExpired from './surveyExpired';
import SubjectiveAnswerType from '../answerTypes/subjectiveAnserType';
import Logo from '../assets/logoSurvey.png'
import HeaderCountdownTimer from './headerCountDown';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import required styles

export default class ParticipantAttendees extends Component {
    constructor(props) {
        super(props);
        this.answerTypeRef = React.createRef();
        this.state = {
            participantAttendees: this.props.participantAttendees || {
                endDate: '',
                endTime: '',
                participantName: '',
                startDate: '',
                startTime: '',
                status: '',
                surveyEndReminder: '',
                surveyName: '',
                surveyStartReminder: '',
                surveyId: 0,
                startDateTime: '',
                endDateTime: '',
                description: '',
                participantId: 0,
                submissionStartDateTime: null,
                submittedOn: null,
                isSubmitted: '',
                languageId: '',
                isRandomized: ''
            },
            languageId: '1',
            editable: false,
            shouldRender: false,
            data: [],
            attendeesEntity: {},
            convertedDescription: '',
            surveyStarted: false,
            order: [],
            loading: false,
            isDataLoaded : false,
        }

    }

    componentDidMount() {
        const currentDate = new Date();
        const futureDate = new Date(this.state.participantAttendees.startDateTime);
        this.convertHTMLToText(this.state.participantAttendees.description);
        if (currentDate >= futureDate) {
            this.setState({ shouldRender: true, languageId: 1 });
            if (this.state.participantAttendees.submissionStartDateTime !== null) {
                let currentLanguage = this.state.participantAttendees.languageId;
                this.setState({
                    languageId: currentLanguage,
                    editable: true
                }, () => {
                    this.fetchList(this.state.languageId);
                    this.handleLanguageChange(this.state.languageId);
                })

            }
        } else {
            this.interval = setInterval(() => {
                const updatedCurrentDate = new Date();
                if (updatedCurrentDate >= futureDate) {
                    this.setState({ shouldRender: true });
                    clearInterval(this.interval);
                }
            }, 60000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleLanguageChange = (e) => {
        let languageId;
        if (typeof e === 'object') {
            if (e.target.value === 'Select Language') {
                languageId = 1;
            } else {
                languageId = e.target.value;
            }
        } else {
            languageId = e;
        }
        const { surveyId } = this.state.participantAttendees;

        getDataByLanguage(languageId, surveyId)
            .then(res => {
                const updatedParticipantAttendees = { ...this.state.participantAttendees };

                if (res.data) {
                    updatedParticipantAttendees.surveyName = res.data.name;
                    updatedParticipantAttendees.description = res.data.description;
                    updatedParticipantAttendees.submissionStartDateTime = this.state.participantAttendees.submissionStartDateTime;
                }
                this.setState({
                    languageId,
                    participantAttendees: updatedParticipantAttendees
                }, () => {
                    if (languageId === '1' || languageId === 'Select Language') {
                        this.fetchList(1);
                    } else {
                        this.fetchList(languageId);
                    }
                    this.convertHTMLToText(updatedParticipantAttendees.description);
                });
            })
            .catch(error => {
                console.log(error);
            });
    }



    fetchList = async (languageId) => {
        try {
            getAllQuestionAndAnswer(
                this.props.participantAttendees.surveyId,
                languageId,
                this.state.participantAttendees.participantId
            )
                .then((res) => {
                    if (res.status === 'OK') {
                        var dbData = null;
                        const { order } = this.state;

                        var dbOrder = order;

                        if (this.state.participantAttendees.isRandomized === true) {
                            if (order.length > 0) {
                                dbData.sort((a, b) => {
                                    const indexA = order.findIndex(item => item.id === a.id);
                                    const indexB = order.findIndex(item => item.id === b.id);
                                    return indexA - indexB;
                                });
                            } else {
                                dbData = this.shuffleArray(res.data.surveyQuestionAnswer);

                                dbData.forEach((element, index) => {
                                    dbOrder.push({
                                        id: element.surveyQuestionResponse.id
                                    })
                                });

                            }
                            res.data.surveyQuestionAnswer = dbData;


                        } else {
                            dbData = res.data.surveyQuestionAnswer.sort((a, b) => a.surveyQuestionResponse.sortOrder - b.surveyQuestionResponse.sortOrder);
                            res.data.surveyQuestionAnswer = dbData;
                        }

                       

                        this.setState({
                            data: res.data,
                            order: dbOrder,
                            loading: true,
                            isDataLoaded: true
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        } catch (error) {
            console.error(error);
        }
    };

    shuffleArray(array) {
        const shuffledArray = [...array];
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const randomIndex = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[randomIndex]] = [shuffledArray[randomIndex], shuffledArray[i]];
        }
        return shuffledArray;
    }

    convertHTMLToText(html) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const text = this.parseNode(doc.documentElement);
        this.setState({ convertedDescription: text });
    }

    parseNode(node) {
        let result = '';

        for (let i = 0; i < node.childNodes.length; i++) {
            const child = node.childNodes[i];

            if (child.nodeType === Node.TEXT_NODE) {
                result += child.textContent;
            } else if (child.nodeType === Node.ELEMENT_NODE) {
                if (child.nodeName === 'STRONG' || child.nodeName === 'B') {
                    result += '<strong>' + this.parseNode(child) + '</strong>';
                } else if (child.nodeName === 'EM' || child.nodeName === 'I') {
                    result += '<em>' + this.parseNode(child) + '</em>';
                } else if (child.nodeName === 'SPAN' && child.style.color) {
                    const color = child.style.color;
                    result += '<span style="color:' + color + '">' + this.parseNode(child) + '</span>';
                } else {
                    result += this.parseNode(child);
                }
            }
        }

        return result;
    }





    onSurveyStart = () => {
        const { participantAttendees, languageId } = this.state;

        startSurvey(participantAttendees.surveyId, languageId, participantAttendees.participantId)
            .then(res => {
                if (res.status === 'OK') {
                    this.setState({
                        attendeesEntity: res.data,
                        surveyStarted: true,
                    }, () => {
                        if (languageId === '1' || languageId === 'Select Language') {
                            this.fetchList(1);
                            this.handleLanguageChange(1);
                        } else {
                            this.fetchList(languageId);
                            this.handleLanguageChange(languageId)
                        }
                        window.location.reload();
                    });
                } else {
                    console.error("Invalid response received from start Survey");
                }
            })
            .catch(error => {
                console.error(error);
            });
    };
    submit = async () => {
        this.setState({ isDataLoaded: false }, async () => {  
            await this.fetchList(this.state.participantAttendees.languageId);
    
            const waitForDataLoad = async () => {
                while (!this.state.isDataLoaded) {
                    await new Promise(resolve => setTimeout(resolve, 100)); // Wait for 100ms before checking again
                }
            };
            await waitForDataLoad();
            if (this.answerTypeRef.current) {
                await this.answerTypeRef.current.triggerBlur();
            }
                confirmAlert({
                    title: `Submit Survey`,
                    message: 'Are you sure, you want to Submit Survey?',
                    buttons: [
                        {
                            label: 'Yes',
                            onClick: () => this.onSubmitSurvey(),
                        },
                        {
                            label: 'No',
                            onClick: () => { }
                        }
                    ]
                });
        });
    };
    onSubmitSurvey = () => {
        const { participantAttendees, languageId, data } = this.state;

        for (let i = 0; i < data.surveyQuestionAnswer.length; i++) {
            if (data.surveyQuestionAnswer[i].surveyQuestionResponse.isMandatory === true) {
                const answer = data.surveyQuestionAnswer[i].surveyResponseAnswers.answers;
                // if (answer === null || answer.trim() === '' || answer === "[]") {
                if (answer === null || answer.trim() === '' || (Array.isArray(answer) && answer.length === 0) || answer === "[]") {
                    toast.error("Please Answer All Mandatory Questions");
                    return;
                }
            } else {
                continue;
            }
        }

        submitSurvey(participantAttendees.surveyId, languageId, participantAttendees.participantId)
            .then(res => {
                if (res.status === 'OK') {
                    let message = res.data;
                    this.props.history.push('/survey/submitted', { message: message });
                } else {
                    console.error("Invalid response received from startSurvey");
                }
            })
            .catch(error => {
                console.error(error);
            });
    }
    onSaveAsDraft = () => {
        toast.success("Survey Saved As Draft");
    };

    formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);

        // Removing the comma from the formatted date
        const formattedDateWithoutComma = formattedDate.replace(',', '');

        // Adding a space before the month
        const [month, day, year] = formattedDateWithoutComma.split(' ');
        return `${day}  ${month} ${year}`;
    };

    formatTime = (dateTime) => {
        const date = new Date(dateTime);
        const hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        let period = 'AM';

        let parsedHours = hours;

        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                parsedHours = hours - 12;
            }
        }

        if (parsedHours === 0) {
            parsedHours = 12;
        }

        return `${parsedHours}:${minutes} ${period}`;
    };

    formatDescription = (description) => {
        if (!description) {
            return ""; // Return an empty string if description is undefined or null
        }
        // Step 1: Remove <span> and <o:p> tags
        let cleanedDescription = description.replace(/<\/?span[^>]*>/g, "").replace(/<\/?o:p>/g, "");
    
        // Step 2: Replace closing `</p>` tags with `<br>` followed by a newline for separation
        cleanedDescription = cleanedDescription
            .replace(/<p[^>]*>/g, "")   // Remove all opening <p> tags
            .replace(/<\/p>/g, "<br>"); // Replace all closing </p> tags with <br>
    
        return cleanedDescription.trim();
    };

    render() {
        const { participantAttendees, languageId, editable, shouldRender, data, convertedDescription, surveyStarted } = this.state; 
        const targetStartDate = new Date(participantAttendees.startDateTime);
        const targetEndDate = new Date(participantAttendees.endDateTime);
        const now = new Date().getTime();
        const surveyExpired = targetEndDate.getTime() - now >= 0;

        return (
            <div className="">
                <Helmet>
                    <title> Survey Page </title>
                </Helmet>
                {surveyExpired &&
                    <div className="content">
                        <div className="">
                            <div className="survey-header">
                                <div className="logo">
                                    <a style={{ cursor: 'revert' }} >
                                        <img className="logoImgSurvey" src={Logo} alt="WorkPlus" width={"125px"} height={"25px"} />
                                    </a>
                                </div>
                                <div className="survey-title-container">
                                    <p className="survey-title">{participantAttendees.surveyName}</p>
                                </div>
                                <div className="timer">{shouldRender &&
                                    <div className="float-right col-auto ml-auto">
                                        <HeaderCountdownTimer targetDate={targetEndDate} />
                                    </div>}</div>
                            </div>
                            

                            <div className="">
                                <div className='row '>
                                    <div className='col-md-12' >

                                        {shouldRender ? <>
                                            <div style={{ background: '#e3e3e3', padding: '17px' }}>
                                                <div className="survey-title-mobile">
                                                    <h3 style={{ color: '#102746' }} className="m-0 survey-title">{participantAttendees.surveyName}</h3>
                                                </div>
                                                <p className="survey_description">
                                                    <div className='desc-box' dangerouslySetInnerHTML={{ __html: this.formatDescription(participantAttendees.description) }}></div>
                                                </p>
                                                <div className='d-flex'>
                                                    <div className='col-md-2'>
                                                        <label>Select Language <span style={{ color: '#102746' }} className="required">*</span></label>
                                                        <SurveyLanguageDropdown
                                                            surveyId={participantAttendees.surveyId}
                                                            readOnly={editable}
                                                            defaultValue={languageId}
                                                            onChange={this.handleLanguageChange}
                                                        />
                                                    </div>
                                                    <div style={{ placeContent: 'center', marginTop: '30px' }}>
                                                        {participantAttendees.submissionStartDateTime === null && !surveyStarted && shouldRender && (
                                                            <button style={{ background: '#102746' }} className="btn btn-secondary" onClick={this.onSurveyStart}>
                                                                Start
                                                            </button>
                                                        )}
                                                    </div>

                                                </div>

                                            </div>

                                             
                                            <div className="newSurvey-body">
                                                <div className="mandatorySymbol">
                                                    <span style={{ color: '#102746' }}>*</span>
                                                    <span> Mandatory Questions</span>
                                                </div>
                                                {data && participantAttendees.submissionStartDateTime !== null && data.surveyQuestionAnswer && data.surveyQuestionAnswer.length > 0 ? (
                                                    <div className='mt-2'>
                                                        {data.surveyQuestionAnswer.map((item, index) => (
                                                            <div style={{ fontSize: '14px', fontWeight: 600 }} className='' key={`${index}-${item.surveyQuestionResponse.id}`}>
                                                                <br />
                                                                <div className='mt-2 d-flex'>
                                                                    <label className='mr-1'>{index + 1}.  </label>
                                                                    <label className=''>
                                                                        {item.surveyQuestionResponse.isMandatory ? (
                                                                            <span style={{ color: '#102746' }} >{item.surveyQuestionResponse.question}<span style={{ color: '#102746' }}>*</span></span>
                                                                        ) : (
                                                                            <span style={{ color: '#102746' }}>{item.surveyQuestionResponse.question}</span>
                                                                        )}
                                                                    </label>
                                                                </div>
                                                                {item.surveyQuestionResponse.answerType === 'SINGLE_ANSWER' ? (
                                                                    <SingleAnswerType answers={item.surveyQuestionResponse.answers} participantAttendees={this.state.participantAttendees} surveyQuestionResponse={item.surveyQuestionResponse} surveyResponseAnswers={item.surveyResponseAnswers} fetchList={this.fetchList} />
                                                                ) : item.surveyQuestionResponse.answerType === 'MULTIPLE_ANSWER' ? (
                                                                    <MultipleAnswerType answers={item.surveyQuestionResponse.answers} participantAttendees={this.state.participantAttendees} surveyQuestionResponse={item.surveyQuestionResponse} surveyResponseAnswers={item.surveyResponseAnswers} fetchList={this.fetchList} />
                                                                ) : item.surveyQuestionResponse.answerType === 'NUMBER_SCALE' ? (
                                                                    <NumberScaleComponent answers={item.surveyQuestionResponse.answers} participantAttendees={this.state.participantAttendees} surveyQuestionResponse={item.surveyQuestionResponse} surveyResponseAnswers={item.surveyResponseAnswers} fetchList={this.fetchList} />
                                                                ) : item.surveyQuestionResponse.answerType === 'SUBJECTIVE' ? (
                                                                    <SubjectiveAnswerType ref={this.answerTypeRef} answers={item.surveyQuestionResponse.answers} participantAttendees={this.state.participantAttendees} surveyQuestionResponse={item.surveyQuestionResponse} surveyResponseAnswers={item.surveyResponseAnswers} fetchList={this.fetchList} />
                                                                ) : (
                                                                    item.surveyQuestionResponse.answers.map((answer) => (
                                                                        <div key={answer.id}>{answer.answer}</div>
                                                                    ))
                                                                )}
                                                            </div>
                                                        ))}
                                                        <div className="survey-button-group">
                                                            <button type="button" onClick={this.onSaveAsDraft} className="btn btn-secondary">Save as Draft</button>
                                                            <button className="submit btn btn-secondary" style={{ background: '#102746' }} onClick={this.submit}>Submit</button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    data && data.surveyQuestionAnswer && data.surveyQuestionAnswer.length === 0 ? (
                                                        <div className="row m-t-30">
                                                            <div className='col dnf-wrapper'>
                                                                <div className='dnf-content dnf-exclam'>
                                                                    <p className='dnf-exclam-mark'>!</p>
                                                                </div>
                                                                <div className='dnf-content dnf-text'>
                                                                    <p className='dnf'>Data Not Found</p>
                                                                    <p>Maybe go back and try a different keyword?</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div></div>
                                                    )
                                                )}

                                            </div>
                                        </>
                                            :
                                            <>

                                                <div className="starting-time">
                                                    <div className="col-8 mt-5">
                                                        <h2 className='survey-start-text'>Survey Starts In</h2>
                                                        <CountdownTimer targetDate={targetStartDate} />
                                                    </div>
                                                    <div className='d-flex mb-3'>
                                                        <div className='survey-part-info'>
                                                            <h4>Start Date:</h4>
                                                            <p>{participantAttendees.startTime} <br />
                                                                {participantAttendees.startDate}  </p>
                                                        </div>
                                                        <div className='survey-part-info'>
                                                            <h4>End Date: </h4>
                                                            <p>{participantAttendees.endTime} <br />
                                                                {participantAttendees.endDate}  </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {/* </fieldset> */}


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                }
                {this.state.loading && !surveyExpired &&
                    <SurveyExpired></SurveyExpired>}
                <ToastContainer />
            </div>
        );
    }
}