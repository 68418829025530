import React, { Component } from 'react';
import { saveParticipantQuestion } from '../surveyParticipantAttendees/service';
import { toast } from 'react-toastify';

class MultipleAnswerType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAnswerIds: [],
      selectedAnswers: [],
      answerIdsResponse: [],
      answersResponse: [],
    };
  }

  handleAnswerClick = (e) => {
    const isChecked = e.target.checked;
    const answerId = e.target.value;
    const answerText = e.target.nextSibling.textContent;

    this.setState((prevState) => {
      let selectedAnswerIds = [...prevState.selectedAnswerIds];
      let selectedAnswers = [...prevState.selectedAnswers];

      if (isChecked) {
        if (!selectedAnswerIds.includes(answerId)) {
          selectedAnswerIds.push(answerId);
        }
        const answer = { id: answerId, answer: answerText };
        if (!selectedAnswers.find((item) => item.id === answerId)) {
          selectedAnswers.push(answer);
        }
      } else {
        selectedAnswerIds = selectedAnswerIds.filter((id) => id !== answerId);
        selectedAnswers = selectedAnswers.filter((item) => item.id !== answerId);
      }

      return { selectedAnswerIds, selectedAnswers };
    }, () => {
      this.handleSave();
    });
  };

  handleSave = () => {
    const { participantAttendees, surveyQuestionResponse, surveyResponseAnswers } = this.props;
    const { selectedAnswerIds, selectedAnswers } = this.state;

    const participantQuestion = {
      answerIds: JSON.stringify(selectedAnswerIds),
      answers: JSON.stringify(selectedAnswers),
      id: surveyResponseAnswers.id || 0,
      participantId: participantAttendees.participantId,
      question: surveyResponseAnswers.question || surveyQuestionResponse.question,
      questionId: surveyResponseAnswers.questionId || surveyQuestionResponse.id,
      surveyId: surveyQuestionResponse.surveyId || '',
      languageId: surveyQuestionResponse.languageId
    };

    this.save(participantQuestion);
  };

  componentDidMount() {
    const { surveyResponseAnswers } = this.props;
    const answerIds = JSON.parse(surveyResponseAnswers.answerIds || '[]');
    const answers = JSON.parse(surveyResponseAnswers.answers || '[]');
    this.setState({
      selectedAnswerIds: answerIds,
      selectedAnswers: answers,
    })
  }

  save = (participantQuestion) => {
    saveParticipantQuestion(participantQuestion)
      .then((res) => {
        if (res.status === 'OK') {
          this.props.fetchList(this.props.participantAttendees.languageId);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        console.log({ err });
        toast.error('Error while saving answer');
      });
  };

  render() {
    const { answers } = this.props;
    const { selectedAnswerIds } = this.state;
    return (
      <div className='radio_buttons_row'>
        {answers.map((answer) => (
          <div key={answer.id}>
            <div className="survey_singleSelectionBtn">
              <input
              style={{alignSelf: 'center', marginTop: "2px"}}
                className=""
                type="checkbox"
                name="answerids"
                value={answer.id}
                checked={selectedAnswerIds.includes(answer.id.toString())}
                onClick={this.handleAnswerClick}
              />
              {!answer.image && <label className="ml-2 answer_label">{answer.answer}</label>}
            </div>
            {answer.image && <img className='square-image' style={{ marginLeft: "25px", borderRadius: '5%' }} src={answer.image} alt={answer.answer} />}
          </div>
        ))}
      </div>
    );
  }
}

export default MultipleAnswerType;
