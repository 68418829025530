import React, { Component } from "react";
import { toast } from "react-toastify";
import { verifyLink } from "./service";
import { BrowserRouter as Router, Route, Switch,HashRouter, Redirect } from 'react-router-dom';
import ParticipantAttendees from "./participantAttendees";
import InvalidUrl from "./invalidUrl";
import SurveyExpired from "./surveyExpired";
import SurveySubmitted from "./surveySubmitted";
import ServiceUnavailable from "./serviceUnavailable";

export default class SurveyParticipantAttendees extends Component {
    constructor(props) {
        super(props);
        this.state = {
            participantAttendees: {
                surveyUrlKey: "",
                participantUrlKey: "",
            },
        };
    }

    componentDidMount() {
        const link = window.location.href;
        const urlParts = link.split('/');
        const uuid1 = urlParts[6];
        const uuid2 = urlParts[7];
        this.setState(
            {
                participantAttendees: {
                    surveyUrlKey: uuid1,
                    participantUrlKey: uuid2
                }
            },
            () => {
                this.verifyLink(this.state.participantAttendees);
            }
        );
    }
    convertToUserDateTimeZone(dateTimeString) {
        const datetime = new Date(dateTimeString);
        const localTime = new Date(datetime.getTime() - datetime.getTimezoneOffset() * 60000);
    
        const year = localTime.getFullYear();
        const month = (localTime.getMonth() + 1).toString().padStart(2, '0');  // Months are 0-indexed
        const day = localTime.getDate().toString().padStart(2, '0');
        const hour = localTime.getHours().toString().padStart(2, '0');
        const minute = localTime.getMinutes().toString().padStart(2, '0');
    
        const formattedDate = `${year}-${month}-${day}`;
        const formattedTime = `${hour}:${minute}`;
        
        return `${formattedDate} ${formattedTime}`;
    }

    verifyLink = (data) => {
        verifyLink(data)
            .then(res => {
                if (res.status === "OK") {
                    toast.success(res.message);
                    ["start", "end"].forEach((key) => {
                        const dateKey = `${key}Date`;
                        const timeKey = `${key}Time`;
                
                        if (res.data[dateKey] && res.data[timeKey]) {
                            const localDateTime = this.convertToUserDateTimeZone(`${res.data[dateKey]}T${res.data[timeKey]}`);
                            const [localDate, localTime] = localDateTime.split(" ");
                            res.data[dateKey] = localDate;
                            res.data[timeKey] = localTime;
                        }
                        if (res.data[`${key}DateTime`]) {
                            res.data[`${key}DateTime`] = this.convertToUserDateTimeZone(res.data[`${key}DateTime`]);
                        }
                    });
                    this.setState({ participantAttendees: res.data});
                }
                else if (res.status == "ALREADY_REPORTED") {
                    let message = res.data;
                    this.props.history.push('/survey/submitted', { message: message });
                }
                else if (res.status == "NOT_FOUND") {
                    this.props.history.push('/survey/not-found');
                }
                else if (res.status == "BAD_REQUEST") {
                    this.props.history.push('/survey/expired');
                }
                else if (res.status == "SERVICE_UNAVAILABLE") {
                    this.props.history.push('/survey/service-unavailable');
                }
            })
    }

    render() {
        return (
                <Switch>
                    <Route exact path={`/survey/not-found`} component={InvalidUrl} />
                    <Route exact path={`/survey/service-unavailable`} component={ServiceUnavailable} />
                    <Route exact path={`/survey/expired`} component={SurveyExpired} />
                    <Route exact path={`/survey/submitted`} component={SurveySubmitted} />
                    <Route exact path={''} component={(props) => <ParticipantAttendees {...props} participantAttendees={this.state.participantAttendees} />} />
                </Switch>
        );
    }
}
